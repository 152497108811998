@import '../assets/css/style.css';
@import '../assets/css/animate.min.css';
@import '../assets/css/bootstrap-icons.css';
@import '../assets/css/bootstrap.min.css';
@import '../assets/css/boxicons.min.css';
@import '../assets/css/jquery.fancybox.min.css';
@import '../assets/css/slick.css';
@import '../assets/css/slick-theme.css';
@import '../assets/css/swiper-bundle.min.css';

body {
    margin: 0;
    color: #333;
    font-family: sans-serif;
}
.header {
    padding: 0.2rem;
    color: #fff;
    background-color: #000;
}
.header.secondary {
    background-color: #333;
}
nav {
    max-width: 62rem;
    margin: 1.5rem auto;
}
ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
li {
    margin-right: 1rem;
}
.header.secondary li:nth-last-child(3) {
    margin-right: auto;
}
.header a {
    color: #fff;
    text-decoration: none;
}
.header.home a[href='/'],
.header.page-router a[href$='/page-router'],
.header.profile a[href$='/profile'],
.header.edge-profile a[href$='/edge-profile'],
.header.profile-middleware a[href$='/profile-middleware'],
.header.profile-api a[href$='/profile-api'],
.header.edge-profile-api a[href$='/edge-profile-api'],
a.active {
    color: #888;
}
button {
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border: none;
    background: none;
}
.container {
    max-width: 62rem;
    margin: 1.5rem auto;
}


.tox-tinymce {
  border: none !important
}

.tox-editor-header,
.tox-menubar,.tox-toolbar-overlord,
.tox .tox-edit-area__iframe {
  background-color: #242627 !important;
}

.tox-menubar button,
.mce-content-body  {
  color: white !important;
}


.action-button {
    --btn-color: rgb(124 45 18);
    --btn-bg: #F88000;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: var(--btn-bg);
    color: white;
    text-decoration: none;
    padding: 14px 22px;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    font-family: system-ui;
    border: 2px solid var(--btn-color);
    transition: 100ms ease;
    box-shadow: 5px 5px 0 0 var(--btn-color);
}

.action-button-secondary {
    --btn-color: black;
    --btn-bg: #4F38AA;
    background-color: #242627;
    color: white;
    border: 2px solid var(--btn-color);
    box-shadow: 5px 5px 0 0 var(--btn-color);
}

.action-button:hover {
    box-shadow: 2px 2px 0 0 var(--btn-color);
}

.action-button:active {
    transition: 50ms ease;
    box-shadow:  0 0 0 0 var(--btn-color);
}


/* cotizar-proyectos */

body {
	background-color: #121212;
	color: #ffffff;
}
.breadcrumb-section {
	background-color: #1a1a1a;
	border-bottom: 1px solid #333;
}
.btn-neon {
	padding: 10px 20px;
	background: transparent;
	color: #fff;
	border: 2px solid #6200ea;
	border-radius: 25px;
	cursor: pointer;
	transition: all 0.3s ease;
	outline: none;
}
.btn-neon:hover {
	border-color: #6200ea;
    scale: 1.05;
}
.btn-neon:disabled {
	color: #555;
	border-color: #555;
	cursor: not-allowed;
	box-shadow: none;
}
.text-white {
	color: #ffffff;
}


.email-input {
    width: 100%;
    max-width: 400px;
    padding: 12px 20px;
    margin-bottom: 20px;
    background-color: #1a1a1a;
    color: #ffffff;
    border: 2px solid #6200ea;
    border-radius: 25px;
    font-size: 1rem;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .email-input::placeholder {
    color: #888;
  }
  
  .email-input:focus {
    outline: none;
    border-color: #bb86fc;
    box-shadow: 0 0 10px #6200ea, 0 0 20px #6200ea;
  }
  
  .email-input:hover {
    border-color: #bb86fc;
  }
  